<template>
    <div class="setting-container">
<!--        <common-header>设置</common-header>-->
        <div class="item-container">
            <div @click="toModifyPassword" class="setting-con01">
                <span class="setting-lab01">修改密码</span>
                <input type="text" readonly class="setting-ipt01 setting-ipt05">
            </div>
<!--            <div @click="toNoOpen('意見反饋')" class="setting-con01">-->
<!--                <span class="setting-lab01">意見反饋</span>-->
<!--                <input type="text" readonly class="setting-ipt01 setting-ipt05">-->
<!--            </div>-->
             <div @click="toRead" class="setting-con01">
                <span class="setting-lab01">用戶協議</span>
                <input type="text" readonly class="setting-ipt01 setting-ipt05">
            </div>
<!--             <div @click="toNoOpen('隱私政策')" class="setting-con01">-->
<!--                <span class="setting-lab01">隱私政策</span>-->
<!--                <input type="text" readonly class="setting-ipt01 setting-ipt05">-->
<!--            </div>-->
<!--             <div @click="toNoOpen('關於我們')" class="setting-con01">-->
<!--                <span class="setting-lab01">關於我們</span>-->
<!--                <input type="text" readonly class="setting-ipt01 setting-ipt05">-->
<!--            </div>-->
            <div class="setting-con01">
                <span class="setting-lab01">版本信息</span>
                <input type="text" value="v0.9" readonly class="setting-ipt01 setting-ipt-right">
            </div>
            <div class="logout-btn" @click="logout">退出登錄</div>
        </div>
    </div>
</template>
<script>
import CommonHeader from '@/components/header'
export default {
    data() {
        return {

        }
    },
    components: {
        // CommonHeader
    },
    methods: {
        logout() {
            this.$dialog.confirm({
                title: '溫馨提示',
                message: '確定要退出登錄嗎？',
            })
            .then(() => {
                // 退出登录
                this.$store.dispatch('logout').then(res => {
                    if(res.code == 200) {
                        localStorage.removeItem('user_token')
                        this.$router.push('/login')
                    }
                })
            })
            .catch(() => {
                // on cancel
            });
        },
        toModifyPassword() {
            // 跳转到修改密码界面
            this.$router.push('/modifyPassword')
        },
        toNoOpen(title) {
            this.$router.push(`/noOpen?title=${title}`)
        },
        toRead() {
            this.$router.push('/read')
        }
    }
}
</script>
<style lang="scss" scoped>
.setting-container {
    min-height: 100vh;
    .setting-con01 {
        background: #fff;
    }

    .setting-ipt-right {
        text-align: right;
    }
    .logout-btn {
        width: 240px;
        height: 40px;
        line-height: 40px;
        color: #fff;
        background: #FF4E50;
        margin: 73px auto 0;
        border-radius: 20px;
    }

    .item-container {
        padding-top: 0px;
    }
}
</style>
