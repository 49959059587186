<template>
    <div class="header-container" :class="hideLine ? 'hide-line' : ''">
        <span v-if="!hide" @click="historyGoBack" class="arrow">
            <img :src="arrowSrc" alt="">
        </span>
        <span class="title">
            <slot></slot>
        </span>
    </div>
</template>
<script>
export default {
    props: ['hide', 'toIndex', 'hideLine'], //:hide="true"控制是否隐藏左侧返回按钮 :toIndex表示點擊回退到首頁
    data() {
        return {
            arrowSrc: require('@/assets/images/ze-arrow-left.png')
        }
    },
    methods: {
        historyGoBack() {
            if(this.toIndex) {
                this.$router.push('/index')
            }else {
                history.go(-1)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.header-container {
    font-size: 18px;
    color: #333;
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    position: fixed;
    z-index: 999;
    border-bottom: 1px solid #eee;
    
    &.hide-line {
        border-bottom: none;
    }

    .title {
        display: block;
        width: 100%;
        line-height: 44px;
        height: 44px;
        padding: 0 10px;
        box-sizing: border-box;
    }
    .arrow {
        position: absolute;
        left: 0;
        width: 44px;
        height: 44px;
        padding-top: 10px;
        box-sizing: border-box;

        img {
            width: 24px;
            height: 24px;
        }
    }
}
</style>